import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

import { PriceIndex } from '@redsleeve/oilynx-domain';

import PriceIndexDotsMenu from '@components/CategoryDotsMenu';

type PriceIndexRowProps = {
  priceIndex: PriceIndex;
  deletePriceIndex: (priceIndex: PriceIndex) => unknown;
  updatePriceIndex: (priceIndex: PriceIndex) => unknown;
};

const PriceIndexRow: FC<PriceIndexRowProps> = ({
  priceIndex,
  deletePriceIndex,
  updatePriceIndex,
}) => {
  return (
    <div
      className={clsx(
        'flex border-b items-center border-gray-700 PriceIndexRow-wrapper p-2 hover:bg-background-avg hover:rounded-xl '
      )}
    >
      <div className={clsx(' w-1/4 text-sm font-body text-white opacity-60')}>
        {priceIndex.name}
      </div>

      <div className="flex-1 flex flex-row-reverse">
        <PriceIndexDotsMenu
          deleteButton={'Delete'}
          showOwnerEdit={false}
          editButton={'Edit'}
          deleteHandler={() => deletePriceIndex(priceIndex)}
          editHandler={() => updatePriceIndex(priceIndex)}
        />
      </div>
    </div>
  );
};

export default PriceIndexRow;
