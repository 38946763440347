import { useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import {
  getErrorMessages,
  getInvalidFields,
  getQueryName,
  useModal,
} from '@lib';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useMemo } from 'react';
import * as Yup from 'yup';

import {
  CreatePriceIndexDocument,
  DeletePriceIndexDocument,
  GetPriceIndexesDocument,
  PriceIndex,
  UpdatePriceIndexDocument,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';

import PriceIndexRow from './PriceIndexRow';
import './price-index-management.css';

const priceIndexValidationSchema = Yup.object({
  name: Yup.string().required('PriceIndexes should have a name'),
});

const PriceIndexManagementPage: FC = () => {
  const [createPriceIndex] = useMutation(CreatePriceIndexDocument, {
    refetchQueries: [getQueryName(GetPriceIndexesDocument)],
  });
  const [deletePriceIndex] = useMutation(DeletePriceIndexDocument, {
    refetchQueries: [getQueryName(GetPriceIndexesDocument)],
  });
  const [updatePriceIndex] = useMutation(UpdatePriceIndexDocument);
  const createModal = useModal();
  const deleteModal = useModal<PriceIndex>();
  const updateModal = useModal<PriceIndex>();

  const priceIndexesQuery = useQuery(GetPriceIndexesDocument);

  const priceIndexes = useMemo(
    () => priceIndexesQuery.data?.getPriceIndexes ?? [],
    [priceIndexesQuery.data]
  );

  const createFormik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: priceIndexValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await createPriceIndex({
          variables: {
            priceIndex: {
              name: values.name,
            },
          },
        });

        // success
        createModal.close();
        createFormik.resetForm();

        // TODO success modal or smth
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else
          console.error(
            '[PriceIndexManagementPage] createPriceIndexForm onSubmit',
            ex
          );
      } finally {
        setSubmitting(false);
      }
    },
  });
  const updateFormik = useFormik({
    initialValues: {
      name: '',
    } as {
      name: string;
    },
    validationSchema: priceIndexValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        await updatePriceIndex({
          variables: {
            priceIndexId: updateModal.data.id,
            priceIndex: {
              name: values.name,
            },
          },
        });
        // success
        updateModal.close();
        updateFormik.resetForm();

        // TODO success modal or smth
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else
          console.error(
            '[PriceIndexManagementPage] updatePriceIndexForm onSubmit',
            ex
          );
      } finally {
        setSubmitting(false);
      }
    },
  });

  async function handlePriceIndexDelete() {
    try {
      await deletePriceIndex({
        variables: {
          priceIndexId: deleteModal.data.id,
        },
      });
      // success
      deleteModal.close();

      // TODO success modal or smth
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      if (errorMessages) alert(errorMessages.join('\n'));
      else
        console.error('[PriceIndexManagementPage] handlePriceIndexDelete', ex);
    }
  }

  function renderModals() {
    return (
      <div className={'CreatePriceIndex-wrapper'}>
        <Modal
          title="Add price index"
          contentClassName="MutationPriceIndex-wrapper h-screen lg:h-auto"
          size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
          control={createModal}
          formProps={{
            onSubmit: createFormik.handleSubmit,
          }}
          actions={
            <>
              <Button
                className="ActionButton"
                type="reset"
                onClick={() => {
                  createModal.close();
                  createFormik.resetForm();
                }}
              >
                Cancel
              </Button>
              <>
                <Button
                  className="ActionButton "
                  type="submit"
                  name="submit"
                  variant="primary"
                >
                  Add price index
                </Button>
              </>
            </>
          }
        >
          <div className={clsx('flex flex-col mt-5')}>
            <div className={clsx('w-full mb-5')}>
              <TextInput
                formik={createFormik}
                type="text"
                name="name"
                label="Price Index Name"
              />
            </div>
          </div>
        </Modal>
        <Modal
          title="Permanently delete price index?"
          contentClassName="MutationPriceIndex-wrapper h-screen lg:h-auto"
          size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
          control={deleteModal}
          actions={
            <>
              <Button
                className="ActionButton"
                type="reset"
                onClick={() => {
                  deleteModal.close();
                }}
              >
                Cancel
              </Button>
              <>
                <Button
                  className="ActionButton"
                  type="submit"
                  name="submit"
                  variant="primary"
                  onClick={handlePriceIndexDelete}
                >
                  Delete
                </Button>
              </>
            </>
          }
        >
          <p className={'text-sm opacity-75 fontFamily-emp '}>
            Deleting {deleteModal.data?.name} will permanently remove it, but
            all the existing trades will remain unaffected!
          </p>
        </Modal>
        <Modal
          title="Edit priceIndex"
          control={updateModal}
          contentClassName="MutationPriceIndex-wrapper h-screen lg:h-auto"
          size="w-screen h-screen lg:h-auto lg:max-w-md lg:w-full"
          formProps={{
            onSubmit: updateFormik.handleSubmit,
          }}
          actions={
            <>
              <Button
                className="ActionButton "
                type="reset"
                onClick={() => {
                  updateModal.close();
                  updateFormik.resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                className="ActionButton"
                type="submit"
                name="submit"
                variant="primary"
              >
                Save
              </Button>
            </>
          }
        >
          <div className={clsx('flex flex-col mt-5')}>
            <div className={clsx('w-full mb-5')}>
              <TextInput
                formik={updateFormik}
                type="text"
                name="name"
                label="Price Index Name"
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <Layout
      url="/admin/price-index-management/"
      title="Price Index Management"
      disableIndex
    >
      <div className="max-w-screen-xl	PriceIndexManagement-wrapper pb-10">
        <h3 className="card-title text-center">Price Indexes</h3>
        <div className="flex justify-end">
          <button
            className="justify-center   flex items-center px-3 border-2 border-interaction-enabled shadow-sm text-xs font-bold	 font-emp rounded-md text-white bg-background-dark "
            onClick={() => createModal.open()}
          >
            <span className="text-2xl text-interaction-light pr-1">
              {' '}
              &#xFF0B;
            </span>{' '}
            <span className="pr-2"> Add Price Index</span>
          </button>
        </div>
        <div className="flex  border-b  border-gray-700">
          <div className="w-1/4 font-emp opacity-40	 text-white">
            Price Indexes
          </div>
        </div>

        {priceIndexes.map((priceIndex) => (
          <PriceIndexRow
            key={priceIndex.id}
            priceIndex={priceIndex}
            deletePriceIndex={deleteModal.open}
            updatePriceIndex={(priceIndex) => {
              updateFormik.resetForm({
                values: {
                  name: priceIndex.name,
                },
              });
              updateModal.open(priceIndex);
            }}
          />
        ))}

        {renderModals()}
      </div>
    </Layout>
  );
};

export default PriceIndexManagementPage;
